import { STRINGS } from "@/interfaces/SmartLink/General"

const COLOR_HTML = {
    compare1    : '#FF0033',
    compare2    : '#00CCFF',
    compare3    : '#1F28E9',
    compare4    : '#9B59B6',
    compare5    : '#2874A6',
    compare6    : '#2ECC71',
    compare7    : '#F1C40F',
    compare8    : '#99A3A4',
    compare9    : '#F0B27A',
    compare10   : '#21618C',
    compare11   : '#F7DC6F',
    compare12   : '#F5B041',
    compare13   : '#B400FE',
    compare14   : '#04FA6D',
    green       : '#06CB03',
    yellow      : '#FDFD96',
    red         : '#FF6961',
    grey        : '#CAC8C8',
    single      : '#00CCFF',
    TUM1        : '#E84FC4',
    TUM2        : '#757374',
    TUM3        : '#333D51',
    TUM4        : '#354877',
    drawer      : '#3a9b6b',
}

const COLOR_CHIP:STRINGS = {
    OK              : '#4CAF50',
    ALERT           : 'yellow',
    ALARM           : 'red',
    Predict         : 'blue',
    'Predict SNR'   : 'blue',
    DOWN            : '#9E9E9E',
    LTE             : 'purple'
}


const STRINGS_COLORS ={
    red     : 'red',
    yellow  : 'yellow',
    green   : 'green',
    grey    : 'grey',
}

export default {
    COLOR_HTML,
    COLOR_CHIP,
    STRINGS_COLORS
}