import { APIS_GENERAL, STRINGS } from '@/interfaces/SmartLink/General'
const APIS:APIS_GENERAL = {
    kpi             :   {
        getAllDataKPI   :   `${process.env.VUE_APP_URL_APIS}kpi/getAllDataKPI`,
        getcostwired    :   `${process.env.VUE_APP_URL_APIS}kpi/getCostWiredpeers`,   
        getcostwireless :   `${process.env.VUE_APP_URL_APIS}kpi/getCostWirelesspeers`,
        getAllLatency   :   `${process.env.VUE_APP_URL_APIS}kpi/getAllLatency`, 
    },  
    dashboard       :   {
        getKPIDashboard :   `${process.env.VUE_APP_URL_APIS}dashboard/getKPIDashboard`,
        getOperability  :   `${process.env.VUE_APP_URL_APIS}dashboard/getAllOperability`,
        alarmtodash     :   `${process.env.VUE_APP_URL_APIS}dashboard/getAlarmToDashBoard`,
        alarmrectodash  :   `${process.env.VUE_APP_URL_APIS}dashboard/getAlarmRecurrentToDashBoard`,
        alarm30days     :   `${process.env.VUE_APP_URL_APIS}dashboard/getAlarmLast30Days`,
    },
    topology        :   {
        getGraphTopo    :   `${process.env.VUE_APP_URL_APIS}topology/getGraphTopologyData`,
        getTableTopo    :   `${process.env.VUE_APP_URL_APIS}topology/getTableTopologyData`,
        getStatusLog    :   `${process.env.VUE_APP_URL_APIS}topology/getStatusLogGraph`,
        getAllTopoData  :   `${process.env.VUE_APP_URL_APIS}topology/getAllTopologyData`,
        getSNMPDataByIP :   `${process.env.VUE_APP_URL_APIS}topology/getSNMPDataByIP`,
        getSNMPUPDByIP  :   `${process.env.VUE_APP_URL_APIS}topology/getSNMPUpDateDataByIP`,
        getStatusByIP   :   `${process.env.VUE_APP_URL_APIS}topology/getStatusByIP`,
        getLatencyByIP  :   `${process.env.VUE_APP_URL_APIS}topology/getLatencyByEquip/`,
        getGPSrajant    :   `${process.env.VUE_APP_URL_APIS}topology/getLastGPSInfoRAJANT`,
        getGPSpmp       :   `${process.env.VUE_APP_URL_APIS}topology/getLastGPSInfoPMP`,
        getAllOperaByIP :   `${process.env.VUE_APP_URL_APIS}topology/getAllOperabilityByIP`,
        getAllOpLastDay :   `${process.env.VUE_APP_URL_APIS}topology/getAllOperabilityLastDay`,
    },


    /** *****************************FALTA GENERAL ****************************** */ 
    general         :   {
        inventory       :   `${process.env.VUE_APP_URL_APIS}inventario`,
        inventoryOID    :   `${process.env.VUE_APP_URL_APIS}inventarioOID`,
        getSubtypeInv   :   `${process.env.VUE_APP_URL_APIS}inventario/getSubTypeInventory`,
        getTypeInv      :   `${process.env.VUE_APP_URL_APIS}inventario/getTypeInventory`,
        deleteDataInDB  :   `${process.env.VUE_APP_URL_APIS}inventario/deleteDataInDB`,
        getDBStatus     :   `${process.env.VUE_APP_URL_APIS}dbstatus/getDataBaseStatus`,
        getstatusEquip  :   `${process.env.VUE_APP_URL_APIS}dbstatus/getstatusEquip`,
        login           :   `${process.env.VUE_APP_URL_APIS}login`,
        pagestatus      :   `${process.env.VUE_APP_URL_APIS}pagestatus`,
        // alarmtodash     :   `${process.env.VUE_APP_URL_APIS}dashboard/getAlarmToDashBoard`,
    }, 
    /** *****************************FALTA GENERAL ****************************** */   


    ia              :   {
        getClustering   :   `${process.env.VUE_APP_URL_APIS}clustering/getClustering`,
        getClusterData  :   `${process.env.VUE_APP_URL_APIS}clustering/getClusteringData`,
        get12HourCluster:   `${process.env.VUE_APP_URL_APIS}clustering/getLast12HourClustering`,
    },
    csv             :   {
        getDataInven    :   `${process.env.VUE_APP_URL_APIS}csv/getDataInventory`,
        editDataInven   :   `${process.env.VUE_APP_URL_APIS}csv/editDataInventory`,
        editDataByName  :   `${process.env.VUE_APP_URL_APIS}csv/editDataInventoryByName`,
        insertDataInven :   `${process.env.VUE_APP_URL_APIS}csv/insertDataInventory`
    },

    predict         :   {
        getAllSNRData   :   `${process.env.VUE_APP_URL_APIS}predict/snr/getAllPredSNRData`,
        getTabSNRByIP   :   `${process.env.VUE_APP_URL_APIS}predict/snr/getPredTabSNRDataByIP`,
        getPredSNRByIP  :   `${process.env.VUE_APP_URL_APIS}predict/snr/getPredSNRDataByIP`,
        getRealSNRByIP  :   `${process.env.VUE_APP_URL_APIS}predict/snr/getRealSNRDataByIP`,
        getAllPredRXData:   `${process.env.VUE_APP_URL_APIS}predict/rxlevel/getAllPredictRXLVLData`,
        getPredTabData  :   `${process.env.VUE_APP_URL_APIS}predict/rxlevel/getPredTableRXLVLData`,
        getPredDataByIP :   `${process.env.VUE_APP_URL_APIS}predict/rxlevel/getAllRXLVLPredDataByIP`,
        getRealDataByIP :   `${process.env.VUE_APP_URL_APIS}predict/rxlevel/getAllRXLVLRealDataByIP`,
    }, 
    snr             :   {
        getAllDataSNR   :   `${process.env.VUE_APP_URL_APIS}predict/snr/getAllDataSNR`,
    } ,
    lte             :   {
        getIPLTE        :   `${process.env.VUE_APP_URL_APIS}lte/getIPLTE`, 
        getAllLTEData   :   `${process.env.VUE_APP_URL_APIS}lte/getAllLTEData`,
        getAllGraphData :   `${process.env.VUE_APP_URL_APIS}lte/getAllLTEDataGraph`,
        lte1data        :   `${process.env.VUE_APP_URL_APIS}lte/getLTE1Data`,  //(no se en que consiste esto, verificar)
        graphsnmpmodem  :   `${process.env.VUE_APP_URL_APIS}lte/getSNMPModemALLDataByIP`, 
    },
    server          :   {
        getIPServer     :   `${process.env.VUE_APP_URL_APIS}server/getIPServer`,
        getAllServData  :   `${process.env.VUE_APP_URL_APIS}server/getAllServerData`,
        getAllServGraph :   `${process.env.VUE_APP_URL_APIS}server/getAllServerGraphData`,
        getVirMacServer :   `${process.env.VUE_APP_URL_APIS}server/getVirMacServer`,
        getVirMacByIP   :   `${process.env.VUE_APP_URL_APIS}server/getVirMacByIP`,
        getAlarmVirMac  :   `${process.env.VUE_APP_URL_APIS}server/getAlarmVirMac`
    },
    maps            :   {
        getAllLatLngSM  :   `${process.env.VUE_APP_URL_APIS}maps/getAllLatLngPMPSM`,
        getAllLatLngANY :   `${process.env.VUE_APP_URL_APIS}maps/getAllLatLngANY`,
        getAllLatLngGPS :   `${process.env.VUE_APP_URL_APIS}maps/getAllLatLngGPSTEST`,
        getAllLatLngAP  :   `${process.env.VUE_APP_URL_APIS}maps/getAllLatLngPMPAP`,
        getAllLatLngRAJ :   `${process.env.VUE_APP_URL_APIS}maps/getAllLatLngRAJANT`,
        getCoverage     :   `${process.env.VUE_APP_URL_APIS}maps/getCoverage`,
        getPositTrucks  :   `${process.env.VUE_APP_URL_APIS}maps/getPositionHaulTrucks`,
        getAllTrucks    :   `${process.env.VUE_APP_URL_APIS}maps/getAllHaulTrucks`,
        getTrucksDrives :   `${process.env.VUE_APP_URL_APIS}maps/getAllHaulTrucksDrive`,
        getPosiDrives   :   `${process.env.VUE_APP_URL_APIS}maps/getPositionDrives`,
        getDrivesRajant :   `${process.env.VUE_APP_URL_APIS}maps/getPositionDrivesRajant`,
        rajant          :   `http://192.168.2.200:4000/api/maps/rajant`,
    },
    // alarms          :   {
    //     get             :   `${process.env.VUE_APP_URL_APIS}alarms/getalarms`,
    //     post            :   `${process.env.VUE_APP_URL_APIS}alarms/postalarms`
    // },
    instamesh       :   {
        getWirelessData :   `${process.env.VUE_APP_URL_APIS}instamesh/getAllWirelessData`,
        getWiredData    :   `${process.env.VUE_APP_URL_APIS}instamesh/getAllWiredData`,
        getTempData     :   `${process.env.VUE_APP_URL_APIS}instamesh/getAllTempData`,
        getCostData     :   `${process.env.VUE_APP_URL_APIS}instamesh/getAllCostData`,
        getCostJRData   :   `${process.env.VUE_APP_URL_APIS}instamesh/getAllCostJRData`,
        getAllIPS       :   `${process.env.VUE_APP_URL_APIS}instamesh/getAllIPS`,
        getWirelessByIP :   `${process.env.VUE_APP_URL_APIS}instamesh/getAllWirelessDataByIP`,
        getWiredByIP    :   `${process.env.VUE_APP_URL_APIS}instamesh/getAllWiredDataByIP`,
        getTempByIP     :   `${process.env.VUE_APP_URL_APIS}instamesh/getAllTempDataByIP`,
        getCostByIP     :   `${process.env.VUE_APP_URL_APIS}instamesh/getAllCostDataByIP`,
        getCostJRByIP   :   `${process.env.VUE_APP_URL_APIS}instamesh/getAllCostJRDataByIP`,
    },
    report          :   {
        operability     :   `${process.env.VUE_APP_URL_APIS}report/getWorstOperability`,
        qualitylap      :   `${process.env.VUE_APP_URL_APIS}report/getWorstQualityLAP`,
        qualitysnr      :   `${process.env.VUE_APP_URL_APIS}report/getWorstQualitySNR`,
        latlng          :   `${process.env.VUE_APP_URL_APIS}report/getWorstLatLng`,
        latency         :   `${process.env.VUE_APP_URL_APIS}report/getWorstLatency`,
        tenlatency      :   `${process.env.VUE_APP_URL_APIS}report/getTENWorstLatency`,
        alarmData       :   `${process.env.VUE_APP_URL_APIS}report/getAlarmData`,
        getIPreport     :   `${process.env.VUE_APP_URL_APIS}report/getDataHistory`,
        getTopFive      :   `${process.env.VUE_APP_URL_APIS}report/getTopFiveLatency`,
        getWorstLnLgIP  :   `${process.env.VUE_APP_URL_APIS}report/getWorstLatLngByIp`,
        getWorstLateIP  :   `${process.env.VUE_APP_URL_APIS}report/getWorstLatencyByIp`,
    },
    yaml            :   {
        getYAML         :   `${process.env.VUE_APP_URL_APIS}rutaYAML/getYAML`,
        editYAML        :   `${process.env.VUE_APP_URL_APIS}rutaYAML/editYAML`,
        getAllOIDs      :   `${process.env.VUE_APP_URL_APIS}rutaYAML/getAllOIDs`,
    }       
};


const APIS_STRINGS:STRINGS = {
    pmpap           : 'pmpap',
    table           : 'table',
    graph           : 'graph',
    statuslog       : 'statuslog',
    linkradiohoriz  : 'linkradiohoriz',
    linkradiovert   : 'linkradiovert',
    throughput      : 'throughput',
    latency         : 'latency',
    rajant          : 'rajant',
    snr             : 'snr',
    predict         : '20',
    all             : 'getPredTabData',
    real            : 'getRealDataByIP',
    pred            : 'getPredDataByIP',
    wlan            : 'wlan',
    wlan0           : 'wlan0',
    wlan1           : 'wlan1',
    wlan2           : 'wlan2',
    wlan3           : 'wlan3',
}


export default {
    APIS,
    APIS_STRINGS,
}