import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
// import HomeView               from '@/views/SmartLink/Home/HomeView.vue';
import LoginViewVue           from '@/views/PrincipalHome/LoginView.vue';
import PageDown               from '@/views/PrincipalHome/PageDownView.vue';
import VoidPageView           from '@/views/PrincipalHome/VoidPageView.vue';
import PrincipalPageView      from '@/views/PrincipalHome/PrincipalPageView.vue';
import DashBoardView          from '@/views/SmartLink/Dashboard/DashBoardView.vue';
import InstameshView          from '@/views/SmartLink/Topo - Insta/InstameshView.vue';
import TopologyStatsView      from '@/views/SmartLink/Topo - Insta/TopologyStatsView.vue';
import CONSTSTRINGS           from '@/constants/strings';
import AdminCSVView           from '@/views/SmartLink/Admin/AdminCSVView.vue';
import AdminView              from '@/views/SmartLink/Admin/AdminView.vue';
import DbStatus               from '@/views/SmartLink/Admin/DBstatusview.vue';
import DBMaintenance          from '@/views/SmartLink/Admin/DBmaintenance.vue';
import YAMLMaintenance        from '@/views/SmartLink/Admin/YAMLMaintenance.vue';
import KPIView                from '@/views/SmartLink/KPI/KPIView.vue';
import AllLatency             from '@/views/SmartLink/KPI/AllLatencyView.vue';
import PredictView            from '@/views/SmartLink/IA/PredictView.vue'
import PredictSNR             from '@/views/SmartLink/IA/PredictSNRView.vue'
import HeatMapsView           from '@/views/SmartLink/IA/HeatMapsView.vue'
// import SNRMapsView            from '@/views/SmartLink/IA/SNRMapsView.vue';
import CostMapsView           from '@/views/SmartLink/IA/CostMapsView.vue';
import LTEView                from '@/views/SmartLink/Server - LTE/LTEview.vue';
import ServerView             from '@/views/SmartLink/Server - LTE/ServerView.vue';
import ReportView             from '@/views/SmartLink/Report/ReportView.vue';
import AlarmReportView        from '@/views/SmartLink/Report/AlarmReportView.vue';
import Drive                  from '@/views/SmartLink/Drive/DriveView.vue';
import TestEO                 from '@/views/SmartLink/Test/Test-EO.vue'


const routes: Array<RouteRecordRaw> = [  
  {
    path: '/',
    name: 'Inicio',
    component: VoidPageView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginViewVue
  },
  {
    path: '/principalPage',
    name: 'Home',
    component: PrincipalPageView
  },
  
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.lte}`,              name: 'SmartLink-LTE',            component: LTEView,},
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.report}`,           name: 'SmartLink-Report',         component: ReportView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.servers}`,          name: 'SmartLink-Servers',        component: ServerView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.heatmap}`,          name: 'SmartLink-Heatmap',        component: HeatMapsView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.costmaps}`,         name: 'SmartLink-Costmaps',       component: CostMapsView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.topology}`,         name: 'SmartLink-Topology',       component: TopologyStatsView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.predicts}`,         name: 'SmartLink-PredictRX',      component: PredictView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.admincsv}`,         name: 'SmartLink-AdminCSV',       component: AdminCSVView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.dbstatus}`,         name: 'SmartLink-DBstatus',       component: DbStatus },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.dashboard}`,        name: 'SmartLink-Dashboard',      component: DashBoardView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.instamesh}`,        name: 'SmartLink-Instamesh',      component: InstameshView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.allLatency}`,       name: 'SmartLink-AllLatency',     component: AllLatency },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.management}`,       name: 'SmartLink-Admin',          component: AdminView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.predictsnr}`,       name: 'SmartLink-PredictSNR',     component: PredictSNR },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.alarmreport}`,      name: 'SmartLink-AlarmReport',    component: AlarmReportView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.dbmaintenance}`,    name: 'SmartLink-DbMaintena',     component: DBMaintenance },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.YAMLMaintenance}`,  name: 'SmartLink-YMLMaintena',    component: YAMLMaintenance },
  // { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.snrmaps}`,          name: 'SmartLink-SNRMap',         component: SNRMapsView },
  
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.drive}`,            name: 'SmartLink-Drive',          component: Drive },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.driveraj}`,         name: 'SmartLink-DriveRaj',       component: Drive },


  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.quality_lap}`,      name: 'SmartLink-Quality_lap',    component: KPIView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.quality_snr}`,      name: 'SmartLink-Quality_snr',    component: KPIView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.wiredpeers}`,       name: 'SmartLink-Wiredpeers',     component: KPIView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.latency}`,          name: 'SmartLink-Latency',        component: KPIView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.wirelesspeers}`,    name: 'SmartLink-Wirelesspeers',  component: KPIView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.temp_inst}`,        name: 'SmartLink-Temp_inst',      component: KPIView },
  { path: `/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.operability}`,      name: 'SmartLink-Operability',    component: KPIView },
  {
    path: '/maintenance',
    name: 'PageDown',
    component: PageDown
  },
  
  { path: `/smartlink/test`,              name: 'SmartLink-TEST',            component: TestEO,},
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // Verificar si la ruta solicitada existe en las rutas definidas
  const exists = routes.some(route => route.path === to.path);
  if (!exists) {
    // Si la ruta no existe, redirigir al menú principal
    next(`/smartlink/${CONSTSTRINGS.STRINGS_VIS_PAGES.dashboard}`);
    // next(`/smartlink/test`);
  } else {
    // Si la ruta existe, continuar con la navegación normalmente
    next();
  }
});



export default router
