export const OPERABILITY = [
  {
      "fecha": "2024-09-16 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 15:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 14:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 13:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 13:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 11:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 10:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 10:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 09:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 08:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 08:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 07:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 07:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 06:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 06:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 06:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 05:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 05:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 04:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 04:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 04:30:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-16 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 04:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 03:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 03:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 02:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 01:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-16 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 00:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 00:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-16 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-16 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 23:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 23:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 23:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 22:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 22:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 21:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 21:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 21:15:00",
      "subtipo": "Acceso",
      "Ok": 1,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 21:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 20:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 20:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 19:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 19:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 18:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 18:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 17:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 17:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 16:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 16:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 15:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 13:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 13:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 12:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 12:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 11:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 11:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 10:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 09:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 08:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 08:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 07:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 07:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 06:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 06:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 06:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 06:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 05:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 05:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 03:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 02:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 02:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 02:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 01:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 01:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 01:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 00:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 00:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-15 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 00:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-15 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-15 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 23:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 23:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 22:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 21:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 21:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 20:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 19:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 19:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 19:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 17:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 15:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 14:45:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 14:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 14:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 13:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 13:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 13:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 12:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 11:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 11:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 10:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 09:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 08:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 08:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 07:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 07:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-14 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 06:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 06:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 00:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 00:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-14 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-14 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 23:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 22:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 21:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 21:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 20:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 20:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 19:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 19:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 19:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 17:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-13 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 14:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 14:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 14:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 13:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 13:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 13:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 13:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 12:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 12:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 12:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 12:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 11:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 11:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 11:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 11:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 10:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 10:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 10:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 09:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 09:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 09:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 08:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 08:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 08:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 08:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 07:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 07:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 07:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 07:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 06:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 06:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 06:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 06:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 05:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 05:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 05:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 05:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 04:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 04:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 04:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 04:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 03:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 03:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 03:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 03:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 02:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 02:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 02:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 02:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 01:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 01:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 01:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 01:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 00:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 00:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 00:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-13 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-13 00:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 23:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 23:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 22:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 21:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 20:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 20:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 18:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 18:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 18:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 17:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 17:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 17:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 17:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 16:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 16:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 16:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 16:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 15:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 15:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 12:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 12:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 11:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 11:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 10:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 10:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 08:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 07:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 07:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 06:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 06:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 06:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 05:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 05:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 04:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 04:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 03:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 03:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 02:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 01:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 01:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-12 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 00:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 00:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 00:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-12 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-12 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 23:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 23:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 22:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 22:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 22:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 21:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 20:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 19:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 19:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 19:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 18:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 18:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 17:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 17:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 17:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 16:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 16:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 16:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 15:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 15:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 14:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 14:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 13:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 12:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 11:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 11:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 10:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 09:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 09:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 08:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 08:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 07:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 06:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 06:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 03:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 03:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 02:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-11 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 01:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 00:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 00:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-11 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-11 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 23:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 23:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 22:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 22:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 21:15:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 1,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-09-10 21:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 20:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 20:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 19:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 18:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 17:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 17:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 16:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 16:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 15:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 15:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 14:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 14:00:00",
      "subtipo": "Acceso",
      "Ok": 2,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-10 13:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 13:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 12:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 12:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 11:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 09:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 08:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 08:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 08:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 08:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 07:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 06:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 06:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 06:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 03:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 03:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 02:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 01:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 01:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 00:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 00:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-10 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 00:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-10 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-10 00:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 23:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 22:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 22:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 22:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 21:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 21:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 20:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 20:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 18:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 18:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 17:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 17:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 17:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 16:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 10:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 09:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 08:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 07:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 06:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 06:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 06:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 05:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 05:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 04:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 03:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 02:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-09 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-09 01:45:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-09 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 01:45:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-09 01:30:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-09 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 01:30:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-09 01:15:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-09 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 01:15:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-09 01:00:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-09 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 01:00:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-09 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 00:45:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-09 00:45:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-09 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 00:30:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-09 00:30:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-09 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 00:15:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-09 00:15:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-09 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-09 00:00:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-09 00:00:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 23:45:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 23:45:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 23:30:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 23:30:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 23:15:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 23:15:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 23:00:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 23:00:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 22:45:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 22:45:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 22:30:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 22:30:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 22:15:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 22:15:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 22:00:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 22:00:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 21:45:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 21:45:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 21:30:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 21:30:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 21:15:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 21:15:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 21:00:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 21:00:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 20:45:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 20:45:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 20:30:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 20:30:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 20:15:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 20:15:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 20:00:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 20:00:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 19:45:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 19:45:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 19:30:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 19:30:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 19:15:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 19:15:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 19:00:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 19:00:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 18:45:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 18:45:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 18:30:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 18:30:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 18:15:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 18:15:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 18:00:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 18:00:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 17:45:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 17:45:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 17:30:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 17:30:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 17:15:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 17:15:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 17:00:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 17:00:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 16:45:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 16:45:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 16:30:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 16:30:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 16:15:00",
      "subtipo": "Acceso",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 5
  },
  {
      "fecha": "2024-09-08 16:15:00",
      "subtipo": "Cliente",
      "Ok": 0,
      "Alarm": 0,
      "Alert": 0,
      "Down": 9
  },
  {
      "fecha": "2024-09-08 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 15:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 15:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 14:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 14:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 13:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 12:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 12:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 11:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 11:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 11:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 11:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 10:45:00",
      "subtipo": "Acceso",
      "Ok": 2,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-09-08 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 10:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 09:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 09:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 08:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 08:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 07:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 07:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 06:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 06:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 06:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 05:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 05:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 04:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 04:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 03:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 02:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 02:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 01:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-08 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 01:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-08 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-08 01:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 23:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 23:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 22:45:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 1,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 22:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 22:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 22:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 21:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 21:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 20:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 19:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 19:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 18:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 18:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 17:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 13:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 13:15:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 12:00:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 11:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 11:15:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 11:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 11:00:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 1,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 10:15:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 1,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 10:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 09:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 09:30:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 08:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 08:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 07:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 07:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 07:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 06:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 06:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 06:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 05:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 05:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 05:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 05:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 04:30:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 04:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 04:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 04:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 03:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 02:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 01:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 01:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 01:00:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-07 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 00:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-07 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 00:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-07 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-07 00:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 23:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 23:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 23:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 22:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 22:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 22:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 21:45:00",
      "subtipo": "Cliente",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 2,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 21:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 21:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 21:15:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-06 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 20:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 19:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 19:15:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 19:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 19:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 18:45:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 18:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 18:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 17:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 17:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 17:30:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-09-06 17:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 17:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 16:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 16:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 16:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 16:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 15:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 15:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 14:45:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-06 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 12:00:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-06 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 11:15:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 11:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 10:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 10:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 10:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 09:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 09:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 09:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 08:45:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-09-06 08:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 08:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 07:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 07:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 07:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 06:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 06:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 06:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 05:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 05:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 04:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 03:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 03:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 03:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 02:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 02:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 02:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 01:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-06 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 01:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 00:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 00:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 00:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-06 00:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-06 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 23:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 23:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 23:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 22:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 22:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 21:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 20:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 20:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 20:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 18:30:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 1,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-09-05 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 17:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 16:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 16:30:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 1,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 16:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 14:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 14:30:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 1,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 14:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 14:15:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 14:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 13:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 13:30:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 1,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-09-05 13:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 10:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 10:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 09:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 08:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 07:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 06:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 06:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 06:15:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 2,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 06:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 05:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 05:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 05:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 05:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-05 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 00:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 00:15:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 1,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-09-05 00:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-05 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-05 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 23:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 22:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 22:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 21:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 20:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 20:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 19:30:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-04 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 19:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 19:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 17:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 16:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 16:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 15:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 15:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 12:15:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 12:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 11:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 11:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 10:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 10:30:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 09:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 09:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 09:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 08:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 08:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 07:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 07:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 07:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 06:30:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-04 06:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 06:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 06:00:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-04 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 05:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 05:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 04:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 04:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 03:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 02:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 01:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 01:45:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 1,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-09-04 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 00:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-04 00:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-04 00:00:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-04 00:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-04 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 23:45:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-03 23:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 23:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 23:15:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-03 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 23:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 22:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 22:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 22:00:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-03 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 20:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 20:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 19:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 18:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 18:15:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-03 18:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 17:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 17:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 16:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 15:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 15:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 14:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 14:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 14:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 14:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 13:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 13:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 12:00:00",
      "subtipo": "Acceso",
      "Ok": 2,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-03 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 10:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 09:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 08:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 08:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 07:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 07:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 06:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 06:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 03:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 03:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 02:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 00:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 00:15:00",
      "subtipo": "Acceso",
      "Ok": 2,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-09-03 00:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-03 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-03 00:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-03 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 23:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-02 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 23:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 23:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-02 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 23:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-02 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 22:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-02 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 22:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 22:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-02 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 21:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-02 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 21:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-02 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 20:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-02 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-02 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 19:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-02 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 18:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 17:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 17:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 17:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-02 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 16:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-02 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 15:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 15:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 14:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 14:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 14:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 13:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 13:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 13:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 12:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 12:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 12:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 11:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 11:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 10:30:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 1,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 10:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 10:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 08:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 08:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 07:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 07:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 07:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 06:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 06:45:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 06:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 06:15:00",
      "subtipo": "Acceso",
      "Ok": 2,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 06:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 05:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 05:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 04:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 04:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 04:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 03:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 03:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 03:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 02:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 02:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 01:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 01:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 00:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 00:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 00:15:00",
      "subtipo": "Acceso",
      "Ok": 2,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 00:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-02 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-02 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-02 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 23:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 22:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 22:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 22:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 21:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-01 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 21:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 21:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 20:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 20:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 20:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 20:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 19:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 19:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 18:45:00",
      "subtipo": "Acceso",
      "Ok": 1,
      "Alarm": 3,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-09-01 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 18:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 17:45:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 1,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 17:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 16:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-01 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 15:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-01 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 15:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 14:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 14:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 14:15:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 1,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 14:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 13:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 12:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 11:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 11:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-01 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 11:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-09-01 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 10:30:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 10:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 10:00:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 10:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 09:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 09:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 09:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 08:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 07:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 07:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 06:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 06:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 06:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 05:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 05:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 05:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 04:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 04:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 04:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 04:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 03:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 03:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 03:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 03:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 02:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 02:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 02:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 01:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 01:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-09-01 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 00:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 00:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-09-01 00:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-09-01 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 23:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 23:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 22:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 22:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 21:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 20:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 19:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 19:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 18:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 18:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 17:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 17:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 17:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 16:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 16:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 16:15:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 16:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 15:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 15:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 15:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 14:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 13:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 13:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 13:00:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 1,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-08-31 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 12:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 12:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 12:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 11:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 11:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 11:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 10:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 10:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-31 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 10:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 09:30:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 1,
      "Down": 1
  },
  {
      "fecha": "2024-08-31 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 09:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 08:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 08:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 08:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 08:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 07:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 07:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 06:30:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 06:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 06:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 04:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 04:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 04:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 03:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 02:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 02:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 02:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 02:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 01:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 01:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 01:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 00:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-31 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 00:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-31 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-31 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 23:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 23:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 23:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 22:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 22:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 21:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 21:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 20:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 20:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 20:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 19:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 19:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 19:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 18:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 18:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-30 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 16:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-30 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 15:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-30 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 15:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-30 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 15:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-30 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 14:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-30 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 14:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-30 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 13:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-30 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 13:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-30 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 13:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-30 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 11:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-30 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 09:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 09:00:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 1,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-08-30 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 08:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 08:15:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-08-30 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 07:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 07:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 07:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 06:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 06:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 04:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 04:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 04:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 03:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 01:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 00:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 00:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-30 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 00:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-30 00:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-30 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 23:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 23:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 22:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 22:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 22:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 21:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 21:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 21:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 21:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 20:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 20:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 20:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 19:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 18:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 17:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 17:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 17:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 16:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 16:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 15:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 15:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 14:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 14:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 13:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 13:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 12:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 12:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 11:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 11:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 11:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 10:45:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 10:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 10:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 09:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 09:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 09:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 08:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 08:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 08:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 07:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 07:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 07:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 06:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 06:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 06:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-29 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 04:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 03:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 02:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 02:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 02:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 01:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 00:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-29 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 00:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-29 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-29 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 23:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 23:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 22:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 22:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 22:15:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 22:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 21:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 21:30:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 1,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-08-28 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 21:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 20:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 18:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 17:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 16:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 16:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 16:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 15:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 14:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 13:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 13:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 13:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 12:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 12:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 12:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 11:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 10:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 09:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 09:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 09:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 08:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 07:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 07:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 07:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 06:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-28 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 06:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 05:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 05:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 05:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 04:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 03:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 03:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 03:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 03:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 02:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 02:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 02:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 01:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-28 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 00:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 00:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 00:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-28 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-28 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 23:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 23:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 23:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 22:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 22:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 21:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 21:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 21:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 20:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 20:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 20:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 19:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-27 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 19:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 18:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 18:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 18:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 17:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 2,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 17:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-27 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 17:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 15:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-27 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 15:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 14:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 14:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 13:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 13:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 10:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 10:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 10:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 10:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 09:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 09:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 08:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 08:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 08:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 07:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 07:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 06:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 06:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 06:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 06:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 05:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 05:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 05:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 04:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 04:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 04:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 04:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 03:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 03:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 03:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 03:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 02:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 02:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-27 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 00:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 00:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-27 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-27 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 23:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 23:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 23:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 23:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 22:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 22:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 22:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 22:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 21:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 21:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 21:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 20:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 20:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 19:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 19:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 19:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 19:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 18:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 18:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 18:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 17:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 17:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 17:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 17:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 16:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 16:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 16:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 14:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 14:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 13:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 13:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 13:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 13:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 12:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 12:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 11:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 11:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 11:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 11:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 10:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-26 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 10:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 09:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 09:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 08:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 08:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 08:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 08:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 07:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 07:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 07:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 07:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 06:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 06:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 06:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 06:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 05:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 05:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 05:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 05:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 04:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 04:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 04:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 04:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 03:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 03:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 03:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 03:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 02:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 02:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 02:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 02:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 01:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 01:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 01:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 00:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 00:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-26 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-26 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-26 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 23:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 23:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 23:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 22:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 22:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 21:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 21:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 20:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 20:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 20:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 19:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-25 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 19:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 18:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 17:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 2,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 17:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 17:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 16:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 15:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 15:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 14:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 14:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 13:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 13:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 13:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 12:45:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 12:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 12:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 11:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 11:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 10:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 09:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-25 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 09:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 09:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 08:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 08:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 08:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 08:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 07:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 07:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 07:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 06:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 06:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 06:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 05:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 05:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 05:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 04:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 03:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 03:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 03:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-25 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 02:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 01:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-25 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 01:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-25 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 00:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-25 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 00:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-25 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-25 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-25 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 23:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 23:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 22:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 22:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 21:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 20:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 19:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 18:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 17:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 16:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 15:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 14:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 14:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 13:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 13:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 13:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 12:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 11:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 11:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 10:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 10:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 10:00:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 2,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 09:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 09:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 08:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 08:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 07:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 07:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 07:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 07:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 06:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 06:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 06:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 06:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 05:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 05:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 04:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 03:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 02:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 01:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 01:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 00:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-24 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 00:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-24 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-24 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-24 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 23:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-23 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 22:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-23 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 22:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 22:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-23 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 21:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-23 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 21:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-23 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 21:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 20:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-23 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-23 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 20:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 20:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 19:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 18:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 18:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 18:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 17:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 17:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 17:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 16:30:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 16:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 15:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 14:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 14:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 14:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 13:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 13:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 12:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 11:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 11:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 11:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 10:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 09:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 09:00:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 08:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 07:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 07:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 06:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 06:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 06:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 04:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 04:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 04:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 03:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 03:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 03:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 02:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 02:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 01:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 01:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 01:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 01:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 00:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 00:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-23 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 00:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-23 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-23 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 23:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 23:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 23:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 23:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 22:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 22:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 22:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 21:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 21:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 20:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 19:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 19:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 19:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 17:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 17:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 16:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 16:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 16:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 13:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 13:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 13:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 13:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 12:45:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 12:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 1,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 11:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 11:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 1,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 10:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 10:15:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 10:00:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 09:30:00",
      "subtipo": "Cliente",
      "Ok": 7,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-22 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 09:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 08:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 08:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 07:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 07:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 06:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 06:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 05:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 05:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 05:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 04:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 04:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 04:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 02:45:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 1,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 02:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 02:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-22 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 01:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 01:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 00:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 00:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-22 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-22 00:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 23:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 23:30:00",
      "subtipo": "Acceso",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 1,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 23:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 22:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 21:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 21:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 20:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 20:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 19:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 19:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 18:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 18:30:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 1,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-08-21 18:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 17:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 17:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 17:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 14:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 13:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 11:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 09:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 09:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 09:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 08:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 08:30:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 1,
      "Alert": 0,
      "Down": 1
  },
  {
      "fecha": "2024-08-21 08:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 08:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 08:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 07:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 07:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 06:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 06:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 06:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 06:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 05:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 05:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 05:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 04:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 04:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 04:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 03:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 03:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 03:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 02:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 02:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 01:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 01:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 01:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 01:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-21 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 00:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 00:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-21 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-21 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 23:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 23:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 23:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 23:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 22:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 22:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 21:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 20:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 19:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 19:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 19:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 18:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 18:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 18:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 17:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 17:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 17:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 17:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 16:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 16:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 16:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 16:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 15:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 15:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 15:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 14:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 14:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 14:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 13:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 13:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 13:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 13:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 13:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 13:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 13:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 12:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 12:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 12:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 12:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 12:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 12:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 12:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 12:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 12:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 12:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 12:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 12:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 11:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 11:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 11:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 11:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 11:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 11:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 11:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 11:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 11:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 11:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 11:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 11:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 10:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 10:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 10:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 10:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 10:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 10:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 10:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 10:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 10:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 10:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 10:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 10:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 09:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 09:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 09:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 09:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 09:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 09:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 09:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 09:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 09:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 09:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 09:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 09:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 08:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 08:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 08:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 08:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 08:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 08:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 08:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 08:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 08:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 08:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 08:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 08:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 07:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 07:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 07:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 07:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 07:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 07:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 07:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 07:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 07:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 07:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 07:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 07:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 06:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 06:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 06:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 06:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 06:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 06:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 06:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 06:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 06:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 06:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 06:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 06:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 05:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 05:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 05:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 05:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 05:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 05:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 05:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 05:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 05:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 05:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 05:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 05:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 04:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 04:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 04:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 04:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 04:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 04:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 04:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 04:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 04:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 04:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 04:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 04:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 03:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 03:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 03:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 03:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 03:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 03:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 03:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 03:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 03:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 03:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 03:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 03:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 02:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 02:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 02:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 02:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 02:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 02:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 02:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 02:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 02:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 02:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 02:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 02:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 01:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 01:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 01:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 01:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 01:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 01:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 01:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 01:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 01:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 01:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 01:00:00",
      "subtipo": "Acceso",
      "Ok": 3,
      "Alarm": 0,
      "Alert": 0,
      "Down": 2
  },
  {
      "fecha": "2024-08-20 01:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 00:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 00:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 00:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 00:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 00:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 00:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-20 00:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 00:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 00:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-20 00:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 00:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-20 00:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 23:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 23:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 23:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 23:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 23:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 23:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 23:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 23:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 23:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 23:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 23:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 23:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 22:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 22:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 22:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 22:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 22:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 22:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 22:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 22:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 22:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 22:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 22:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 22:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 21:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 21:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 21:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 21:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 21:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 21:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 21:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 21:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 21:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 21:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 21:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 21:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 20:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 20:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 20:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 20:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 20:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 20:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 20:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 20:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 20:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 20:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 20:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 20:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 19:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 19:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 19:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 19:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 19:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 19:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 19:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 19:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 19:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 19:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 19:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 19:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 18:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 18:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 18:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 18:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 18:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 18:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 18:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 18:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 18:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 18:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 18:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 18:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 17:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 17:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 17:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 17:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 17:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 17:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 17:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 17:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 17:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 17:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 17:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 17:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 1,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 16:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 16:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 16:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 16:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 16:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 16:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 16:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 16:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 16:15:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 16:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 16:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 16:00:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 15:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 15:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 15:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 15:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 15:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 15:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 15:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 15:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 15:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 15:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 15:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 15:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 14:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 14:45:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 14:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 14:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 14:30:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 14:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 14:15:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 14:15:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 14:15:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 14:00:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 1,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 14:00:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 14:00:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 13:45:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 13:45:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 13:45:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 13:30:00",
      "subtipo": "Cliente",
      "Ok": 6,
      "Alarm": 0,
      "Alert": 0,
      "Down": 3
  },
  {
      "fecha": "2024-08-19 13:30:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 13:30:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 13:16:00",
      "subtipo": "Cliente",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 4
  },
  {
      "fecha": "2024-08-19 13:16:00",
      "subtipo": "Distribucion",
      "Ok": 4,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  },
  {
      "fecha": "2024-08-19 13:16:00",
      "subtipo": "Acceso",
      "Ok": 5,
      "Alarm": 0,
      "Alert": 0,
      "Down": 0
  }
]