const IMAGES = { 
    ap              : require("@/assets/Images/pmp-ap.png"),
    // ap              : require("@/assets/Images/pmpap.png"),
    pmp             : require("@/assets/Images/pmpsm.png"),
    solarTrailerGod : require("@/assets/Images/solarTrailer.png"),
    solarTrailer    : require("@/assets/Images/solartrailertest2.png"),
    tower           : require("@/assets/Images/tower.png"),
    showel          : require("@/assets/Images/showel.png"),
    haultruck       : require("@/assets/Images/haultruck.png"),
    driller         : require("@/assets/Images/driller.png"),
    frontLoader     : require("@/assets/Images/frontLoader.png"),
    waterTruck      : require("@/assets/Images/waterTruck.png"),
    lte             : require("@/assets/Images/hcglink.png"),
    server          : require("@/assets/Images/server.png"),
    movil           : require("@/assets/Images/smartphone.png"),
    pc              : require("@/assets/Images/pcimg.png"),
    cpu             : require("@/assets/Images/cpuimg.png"),
    memory          : require("@/assets/Images/memoriaimg.png"),
    disc            : require("@/assets/Images/discoduroimg.png"),
    cliente         : require("@/assets/Images/cliente.png"),
    apt             : require("@/assets/Images/apt.png"),
    leyendLatency   : require("@/assets/leyenda/leyendalatency4.png"),
    logoAntucoya    : require("@/assets/Images/logoantucoya.jpg"),
    logoCananea     : require("@/assets/Images/logocananea.png"),
    logoHCG         : require("@/assets/Images/hcgroup.jpg"),
    // test            : require("@/assets/Img/Green/apttest.png"),
}         

const LEGEND = {
    costMap         : require('@/assets/leyenda/leyendacosto2.png'),
    snmpMap         : require('@/assets/leyenda/leyendasnmp.png'),
}

export default {
    IMAGES,
    LEGEND
}